<template>
<div>
<v-container>
<div class="conteneur">
  <div class="conteneur-fondu">
      <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015" class="logo">
          <img  src="/images/titreMSVacances.png">
      </div>
      <div v-else class="logo">
          <img  src="/images/titreCampeole.png">
      </div>
  </div>

  <div class="conteneur-element-1">
    <div class="element-1">
      <div v-for="(item,index) in questions" :key="item.id">
        <transition :name="slide_sens">
          <v-item-group v-model="form.model[index]" v-if="question_nb==(index+1)" class="container" >
            <!--template format icone simple-->
            <v-row v-if="item.format == 'format-icones' || item.format == 'format-icones-avis' || item.format == 'format-icones-prestations'">
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                  <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                </div>
                <div class="type-question" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>

                </div>
              </v-col>
              <div class="content_answer">
                <div
                v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border"
              >
                  <v-item v-slot="{ active }">
                    <v-card
                      sm="3"
                      @click="onCardClick(indexSmiley,index, item)"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          <img v-bind:src="url" alt="">
                        </div>
                        <div v-if="!active">
                          <img v-bind:src="url" alt="">
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
                </div>
              </div>

            </v-row>
            <!--template format icone simple-->

            <!--template format icone texte-->

            <v-row v-if="item.format == 'format-icones-texte'" justify="space-around">
              <v-col cols="12" sm="12">
                <div v-if="(item.id != 5 || (item.id == 5 && prequestion5 == 1)) && (item.id != 6 || (item.id == 6 && prequestion6 == 1)) 
                && (item.id != 7 || (item.id == 7 && prequestion7 == 1)) && (item.id != 8 || (item.id == 8 && prequestion8 == 1)) 
                && (item.id != 9 || (item.id == 9 && prequestion9 == 1)) && (item.id != 10 || (item.id == 10 && prequestion10 == 1)) 
                && (item.id != 11 || (item.id == 11 && prequestion11 == 1))  && (item.id != 12 || (item.id == 12 && prequestion12 == 1))  
                && (item.id != 17 || (item.id == 17 && prequestion17 == 1))  && (item.id != 18 || (item.id == 18 && prequestion18 == 1))
                && (item.id != 31 || (item.id == 31 && prequestion31 == 1))  && (item.id != 32 || (item.id == 32 && prequestion32 == 1))
                && (item.id != 33 || (item.id == 33 && prequestion33 == 1))  && (item.id != 34 || (item.id == 34 && prequestion34 == 1))
                && (item.id != 35 || (item.id == 35 && prequestion35 == 1))  && (item.id != 36 || (item.id == 36 && prequestion36 == 1))
                && (item.id != 37 || (item.id == 37 && prequestion37 == 1))  && (item.id != 38 || (item.id == 38 && prequestion38 == 1))
                && (item.id != 39 || (item.id == 39 && prequestion39 == 1))" class="questions">
                  <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                    <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                    <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                    <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                    <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  </div>
                  <div class="type-question" :id="'question'+item.id">
                    {{recupQuestionLang(item)}}
                  </div>
                </div>
              </v-col>
              <div v-if="item.id == 1" class="content_content_answer">
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte1Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte1Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 2" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte2Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte2Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 3" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte3Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte3Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 4" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte4Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte4Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 15" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte15Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte15Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 16" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte16Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte16Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 20" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte20Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte20Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 21" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte21Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte21Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 25" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte25Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte25Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              <div v-if="item.id == 26" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte26Autre"
                 rows="2"></v-textarea>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte26Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 5" class="content_content_answer" >
                <v-row v-if="prequestion5 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Were the daytime activities part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Les animations en journée ont-elles fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die tagsüber angebotenen Animationen genutzt?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de begeleide activiteiten overdag?</template>
                      </div>
                    </div>
                  </v-col>
                  <!-- <div class="content_answer">
                    <div
                      v-for="(label,indexOuinon) in ouinon[langue]" :key="indexOuinon"  class="oui-non smiley-border"
                    >
                      <v-item v-slot="{ active }">
                        <v-card
                          :color="active ? 'primary' : ''"
    
                          sm="6"
                          
                        >
                         @click="onCardClick(indexOuinon,index, item, 'ouinon')"
                          <v-scroll-y-transition>
                            <div
                              v-if="active"
                              class="active"
                            >
                              {{label}}
                            </div>
                            <div v-if="!active">
                              {{label}}
                            </div>
                          </v-scroll-y-transition>
                        </v-card>
                      </v-item>
                    </div>
                  </div> -->
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion5 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion5 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion5 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte5Autre"
                 rows="2"></v-textarea>
                 <!-- <div v-if="prequestion5 == 1" class="text-center pointer" @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We did not take advantage of this service</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas profité de cette prestation</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Diesen Service haben wir nicht in Anspruch genommen</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben geen gebruik gemaakt van deze service</p>
                </div> -->
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte5Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              
              <div v-if="item.id == 6" class="content_content_answer" >
                <v-row v-if="prequestion6 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Were the evening activities part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Les animations en soirée ont-elles fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Animationen am Abend genutzt?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de begeleide activiteiten 's avonds?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion6 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion6 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion6 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte6Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte6Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 7" class="content_content_answer" >
                <v-row v-if="prequestion7 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015">
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the MS Bar part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le MS Bar a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die MS Bar besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de MS Bar?</template>
                        </div>
                        <div v-else>
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the bar part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le bar a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Bar besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de bar?</template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion7 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion7 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion7 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte7Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte7Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
              
              <!-- <div v-if="item.id == 8" class="content_content_answer" >
                <v-row class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="form.response[index] == 0 || form.response[index] == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte8Autre"
                 rows="2"></v-textarea>
                <div class="text-center pointer" @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We did not take advantage of this service</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas profité de cette prestation</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Diesen Service haben wir nicht in Anspruch genommen</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben geen gebruik gemaakt van deze service</p>
                </div>
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte8Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div> -->

              <div v-if="item.id == 8" class="content_content_answer" >
                <v-row v-if="prequestion8 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015">
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the MS Sucré part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le MS Sucré a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Süße Theke MS Sucré besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de MS Zoete lekkernijen?</template>
                        </div>
                        <div v-else>
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the sucré part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le sucré a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Süße Theke besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de zoete lekkernijen?</template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion8 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion8 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion8 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte8Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte8Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 9" class="content_content_answer" >
                <v-row v-if="prequestion9 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015">
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the MS Resto part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le MS Resto a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Restaurant MS Resto besucht</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van het MS Restaurant?</template>
                        </div>
                        <div v-else>
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the restaurant part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le restaurant a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Restaurant besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van het restaurant?</template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion9 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion9 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion9 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte9Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte9Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 10" class="content_content_answer" >
                <v-row v-if="prequestion10 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        <div v-if="camping_id == 582 || camping_id == 4085 || camping_id == 4535 || camping_id == 6475 || camping_id == 1015">
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the MS Snack part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le MS Snack a-t-il fait partie de votre expérience durant votre séjour ?</template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Snackbar MS Snack besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de MS Snackbar?</template>
                        </div>
                        <div v-else>
                          <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the snack part of your stay experience?</template>
                          <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le snack a-t-il fait partie de votre expérience durant votre séjour ? </template>
                          <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Snackbar besucht?</template>
                          <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de snackbar?</template>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion10 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion10 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion10 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte10Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte10Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 11" class="content_content_answer" >
                <v-row v-if="prequestion11 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the grocery store part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">L'épicerie a-t-elle fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Lebensmittelgeschäft besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de campingwinkel?</template>
                      </div>
                      
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion11 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion11 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion11 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte11Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte11Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 12" class="content_content_answer" >
                <v-row v-if="prequestion12 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      <div class="type-question" :id="'question'+item.id">
                        Avez-vous profité de l'Espace bien-être ?
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion12 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion12 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion12 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte12Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte12Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 17" class="content_content_answer" >
                <v-row v-if="prequestion17 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Did your children take part in the children club?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Vos enfants ont-ils participé aux clubs enfants durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Ihre Kinder während Ihres Aufenthalts an den Kinderclubs teilgenommen?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebben uw kinderen tijdens uw verblijf gebruikgemaakt van de kinderclubs?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion17 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion17 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion17 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte17Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte17Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 18" class="content_content_answer" >
                <v-row v-if="prequestion18 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Did your teenagers take part in the teen club?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Vos ados ont-ils participé au club ado durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Ihre Teenager während Ihres Aufenthalts am Teens-Club teilgenommen?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebben uw tieners tijdens uw verblijf gebruikgemaakt van de tienerclub?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion18 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion18 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion18 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte18Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte18Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 31" class="content_content_answer" >
                <v-row v-if="prequestion31 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                     
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the baking station (bread, pastries…) part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le point chaud (pain, viennoiseries,…) a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Backstation genutzt (Brot, Feingebäck,…)?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van het hotpoint (brood, zoete broodjes ...)?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion31 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion31 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion31 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte31Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte31Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 32" class="content_content_answer" >
                <v-row v-if="prequestion32 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the hairdresser part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le salon de coiffure a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts den Friseursalon besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de kapsalon?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion32 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion32 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion32 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte32Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte32Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 33" class="content_content_answer" >
                <v-row v-if="prequestion33 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the massage salon part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le salon de massage a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Massageinstitut besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de massagesalon?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion33 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion33 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion33 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte33Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte33Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 34" class="content_content_answer" >
                <v-row v-if="prequestion34 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                     
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the beauty salon part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le salon d'esthétique a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts den Kosmetiksalon besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de schoonheidssalon?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion34 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion34 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion34 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte34Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1))"  @click="onCardClickChoixMultiples(form.response[index],texte34Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 35" class="content_content_answer" >
                <v-row v-if="prequestion35 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the nail salon part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le salon d'onglerie a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Nagelstudio besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de nagelkunst?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion35 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion35 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion35 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte35Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte35Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 36" class="content_content_answer" >
                <v-row v-if="prequestion36 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                     
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the reflexology part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">La réfléxologie a-t-elle fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das Reflexzonenmassage-Angebot genutzt?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de reflexologie?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion36 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion36 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion36 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte36Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte36Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 37" class="content_content_answer" >
                <v-row v-if="prequestion37 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the osteopathy part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">L'ostéopathie a-t-elle fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts das osteopathie-Angebot genutzt?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de osteopathie?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion37 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion37 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion37 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte37Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte37Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 38" class="content_content_answer" >
                <v-row v-if="prequestion38 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                     
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the pizzeria part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">La pizzeria a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts die Pizzeria besucht?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de pizzeria?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion38 = 1">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion38 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion38 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte38Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte38Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>

              <div v-if="item.id == 39" class="content_content_answer" >
                <v-row v-if="prequestion39 != 1">
                  <v-col cols="12" sm="12">
                    <div class="questions">
                      <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                      </div>
                      
                      <div class="type-question" :id="'question'+item.id">
                        <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Was the food truck part of your stay experience?</template>
                        <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Le food truck a-t-il fait partie de votre expérience durant votre séjour ?</template>
                        <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Haben Sie während Ihres Aufenthalts den Foodtruck genutzt?</template>
                        <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Hebt u tijdens uw verblijf gebruikgemaakt van de foodtruck?</template>
                      </div>
                    </div>
                  </v-col>
                  <div class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="prequestion39 = 1">
                        <div> {{ouinon[langue][0]}}</div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                        <div>{{ouinon[langue][1]}}</div>
                      </div>
                    </div>
                  </div>
                </v-row>
                <v-row v-if="prequestion39 == 1" class="content_answer">
                  <div v-for="(url,indexSmiley) in smiley" :key="indexSmiley"  class="smiley smiley-border">
                    <v-item v-slot="{active}" >
                      <v-card sm="3" @click="onCardClickIcones(indexSmiley,index, item)">
                          <div v-if="active" class="active" >
                            <img v-bind:src="url" alt="">
                          </div>
                          <div v-if="!active">
                            <img v-bind:src="url" alt="">
                          </div>
                      </v-card>
                    </v-item>
                  </div>
                </v-row>
                <br>
                <br>
                <v-textarea v-if="(form.response[index] == 0 || form.response[index] == 1) && prequestion39 == 1"
                  clearable
                  counter="500"
                  background-color="#fff"
                  color = "black"
                  clear-icon="mdi-close-circle"
                  value=""
                  :placeholder="placeholder[langue]"
                  :rules="nameRules"
                  v-model="texte39Autre"
                 rows="2"></v-textarea>
                
                <br>
                <br>
                <v-btn v-if="((form.response[index] == 0 || form.response[index] == 1) )"  @click="onCardClickChoixMultiples(form.response[index],texte39Autre,index, item,'iconestexte')">{{boutonS[langue]}}</v-btn>
                
              </div>
            </v-row>
            <!--template format icone texte-->

            <!--template format icone avec choix avis-->
            <v-row v-if="item.format == 'format-icones-avis' ">
              <v-col cols="12" sm="12">
                <div class="text-center pointer"  @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We have no opinion on the matter</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas d'avis sur la question</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Wir haben dazu keine Meinung</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben er geen mening over</p>
                </div>
              </v-col>
            </v-row>
            <!--template format icone avec choix avis-->

            <!--template format icone avec choix prestation-->
            <!-- <v-row v-if="item.format == 'format-icones-prestations' ">
              <v-col cols="12" sm="12">
                <div class="text-center pointer" @click="onCardClick(-1,index, item)">
                  <p v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">We did not take advantage of this service</p>
                  <p v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Nous n'avons pas profité de cette prestation</p>
                  <p v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Diesen Service haben wir nicht in Anspruch genommen</p>
                  <p v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">We hebben geen gebruik gemaakt van deze service</p>
                </div>
              </v-col>
            </v-row> -->
            <!--template format icone avec choix prestation-->

            <!--template format icone avec choix oui-non-->
            <v-row v-if="item.format == 'format-oui-non'">
              <v-col cols="12" sm="12">
                <div class="questions">
                  <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                    <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion -1}}<br /></template>
                    <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                    <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                    <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  </div>
                  <div class="type-question" :id="'question'+item.id">
                    {{recupQuestionLang(item)}}
                  </div>
                </div>
              </v-col>
              <div  v-if="item.id == 42" class="content_answer">
                <div
                  v-for="(label,indexOuinon) in ouinon[langue]" :key="indexOuinon"  class="oui-non smiley-border"
                >
                  <v-item v-slot="{ active }">
                    <v-card
                      :color="active ? 'primary' : ''"

                      sm="6"
                      @click="onCardClick(indexOuinon,index, item, 'ouinon')"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          {{label}}
                        </div>
                        <div v-if="!active">
                          {{label}}
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
         
                </div>
              </div>
              <div v-else class="content_answer">
                <div
                  v-for="(label,indexOuinon) in ouinon[langue]" :key="indexOuinon"  class="oui-non smiley-border"
                >
                  <v-item v-slot="{ active }">
                    <v-card
                      :color="active ? 'primary' : ''"

                      sm="6"
                      @click="onCardClick(indexOuinon,index, item, 'ouinon')"
                    >
                      <v-scroll-y-transition>
                        <div
                          v-if="active"
                          class="active"
                        >
                          {{label}}
                        </div>
                        <div v-if="!active">
                          {{label}}
                        </div>
                      </v-scroll-y-transition>
                    </v-card>
                  </v-item>
         
                </div>
              </div>
            </v-row>



            <!--template format icone avec choix oui-non-->
            <!--template format icone avec choix txt-libre-->
            <v-row v-if="item.format == 'format-texte-libre' ">
              
              
              <v-col cols="12" sm="12">
                <div class="questions">
                <div class="fontfamily-msvacancesregular" ><img v-if="index > 0" @click="before()" src="/images/baliseOuverte.png" alt="">
                  <template v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10">Question {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18">Frage {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                  <template v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24">Vraag {{index + 1}} / {{nbTotalQuestion - 1}}<br /></template>
                </div>
                <div class="type-question type-question-fr" :id="'question'+item.id">
                  {{recupQuestionLang(item)}}
                </div>
                </div>
              </v-col>
              <template v-if="item.id == 26" >
                <v-col cols="12" sm="12">
                    <v-textarea
                        clearable
                        counter="500"
                        background-color="#fff"
                        color = "black"
                        clear-icon="mdi-close-circle"
                        value=""
                        :rules="nameRules"
                        v-model="texteLibre26"
                       rows="2"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                <v-btn v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" elevation="2" @click="onCardClick(texteLibre26,index, item)">Next</v-btn>
                <v-btn v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" elevation="2" @click="onCardClick(texteLibre26,index, item)">Suivant</v-btn>
                <v-btn v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" elevation="2" @click="onCardClick(texteLibre26,index, item)">Folgend</v-btn>
                <v-btn v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" elevation="2" @click="onCardClick(texteLibre26,index, item)">Volgende</v-btn>
                </v-col>
              </template>
              <template v-if="item.id == 27" >
                
                
                <div id="listeThemes" v-html="popup"></div>
                {{remplirPopup(form.theme)}}
                <v-col cols="12" sm="12">
                    <v-textarea
                        clearable
                        counter="500"
                        background-color="#fff"
                        color = "black"
                        clear-icon="mdi-close-circle"
                        value=""
                        :rules="nameRules"
                        v-model="texteLibre27"
                       rows="2"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                <v-btn v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" elevation="2" @click="onCardClick(texteLibre27,index, item)">Next</v-btn>
                <v-btn v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" elevation="2" @click="onCardClick(texteLibre27,index, item)">Suivant</v-btn>
                <v-btn v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" elevation="2" @click="onCardClick(texteLibre27,index, item)">Folgend</v-btn>
                <v-btn v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" elevation="2" @click="onCardClick(texteLibre27,index, item)">Volgende</v-btn>
                </v-col>
              </template>
              <template v-if="item.id == 40" >
                
                
                <!-- <div id="listeThemes" v-html="popup"></div> -->
                {{remplirPopup(form.theme)}}
                <v-col cols="12" sm="12">
                    <v-textarea
                        clearable
                        counter="500"
                        background-color="#fff"
                        color = "black"
                        clear-icon="mdi-close-circle"
                        value=""
                        :rules="nameRules"
                        v-model="texteLibre40"
                       rows="2"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                <v-btn v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" elevation="2" @click="onCardClick(texteLibre40,index, item)">Next</v-btn>
                <v-btn v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" elevation="2" @click="onCardClick(texteLibre40,index, item)">Suivant</v-btn>
                <v-btn v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" elevation="2" @click="onCardClick(texteLibre40,index, item)">Folgend</v-btn>
                <v-btn v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" elevation="2" @click="onCardClick(texteLibre40,index, item)">Volgende</v-btn>
                </v-col>
              </template>
              <template v-if="item.id == 41" >
                
                
                <!-- <div id="listeThemes" v-html="popup"></div> -->
                {{remplirPopup(form.theme)}}
                <v-col cols="12" sm="12">
                    <v-textarea
                        clearable
                        counter="500"
                        background-color="#fff"
                        color = "black"
                        clear-icon="mdi-close-circle"
                        value=""
                        :rules="nameRules"
                        v-model="texteLibre41"
                       rows="2"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                <v-btn v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" elevation="2" @click="onCardClick(texteLibre41,index, item)">Next</v-btn>
                <v-btn v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" elevation="2" @click="onCardClick(texteLibre41,index, item)">Suivant</v-btn>
                <v-btn v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" elevation="2" @click="onCardClick(texteLibre41,index, item)">Folgend</v-btn>
                <v-btn v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" elevation="2" @click="onCardClick(texteLibre41,index, item)">Volgende</v-btn>
                </v-col>
              </template>

              <div v-if="item.id == 43" class="content_answer">
                <template >
                  <div v-if="prequestion43 == 0" class="content_answer">
                      <div class="oui-non smiley-border">
                        <div class="v-card v-sheet theme--light" sm="6" @click="prequestion43 = 1">
                          <div> {{ouinon[langue][0]}}</div>
                        </div>
                      </div>
                      <div class="oui-non smiley-border">
                        <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(-1,index, item)">
                          <div> {{ouinon[langue][1]}} </div>
                        </div>
                      </div>
                    </div>
                    
                  <v-textarea v-if="prequestion43 == 1"
                    clearable
                    counter="500"
                    background-color="#fff"
                    color = "black"
                    clear-icon="mdi-close-circle"
                    value=""
                    :placeholder="placeholder[langue]"
                    :rules="nameRules"
                    v-model="texte43Autre"
                  rows="2"></v-textarea>
                  
                <br>
                <br>
                
                </template>
                <v-btn v-if="prequestion43 == 1" @click="onCardClick(texte43Autre,index, item)">{{boutonS[langue]}}</v-btn>
              </div>
             
              <template v-if="item.id == 42" >
                
                <div v-if="prequestion42 == 0" class="content_answer">
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(texteLibre42,index, item)">
                        <div> {{ouinon[langue][0]}} </div>
                      </div>
                    </div>
                    <div class="oui-non smiley-border">
                      <div class="v-card v-sheet theme--light" sm="6" @click="onCardClick(texteLibre42,index, item)">
                        <div> {{ouinon[langue][1]}} </div>
                      </div>
                    </div>
                  </div>
                <template v-if="prequestion42 == 1">
                  <!-- <div  id="listeThemes" v-html="popup"></div> -->
                  {{remplirPopup(form.theme)}}
                  <v-col cols="12" sm="12">
                      <v-textarea
                          clearable
                          counter="500"
                          background-color="#fff"
                          color = "black"
                          clear-icon="mdi-close-circle"
                          value=""
                          :rules="nameRules"
                          v-model="texteLibre42"
                        rows="2"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12">
                  <v-btn v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" elevation="2" @click="onCardClick(texteLibre42,index, item)">Next</v-btn>
                  <v-btn v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" elevation="2" @click="onCardClick(texteLibre42,index, item)">Suivant</v-btn>
                  <v-btn v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" elevation="2" @click="onCardClick(texteLibre42,index, item)">Folgend</v-btn>
                  <v-btn v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" elevation="2" @click="onCardClick(texteLibre42,index, item)">Volgende</v-btn>
                  </v-col>
                </template>
              </template>

              
              
            </v-row>
            <!--template format icone avec choix txt-libre-->            
          </v-item-group>
        </transition>
      </div>
      <div v-if="valueOuinon && ((nbTotalQuestion) == question_nb)">
        <v-row v-if="valueOuinon == 'Oui' ">
          <v-col cols="12" sm="12">
            
            <div v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" class="type-question">
              <div class="fontfamily-msvacancesregular">Thanks !</div>
              <strong>Obviously your stay <br> was a success and we are very happy!<br></strong>
            </div>
            <div v-else-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" class="type-question">
              <div class="fontfamily-msvacancesregular">Danke !</div>
              <strong>Offensichtlich haben Sie bei uns<br>einen gelungenen Urlaub verbracht<br>und darüber sind wir sehr froh!<br></strong>
            </div>
            <div v-else-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" class="type-question">
              <div class="fontfamily-msvacancesregular">Dankjewel !</div>
              <strong>Zo te zien was uw verblijf een succes.<br>Dat horen we graag natuurlijk!<br></strong>
            </div>
            <div v-else class="type-question">
              <div class="fontfamily-msvacancesregular">Merci !</div>
              <strong>Visiblement votre séjour<br>fut une réussite et nous en sommes<br>très heureux!<br></strong>
            </div>
            
            


            <div class="separation"></div>
            <div v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" class="type-question">
              <strong>We would be delighted if you would <br> share with us your MS experience</strong>
            </div>
            <div v-else-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" class="type-question">
              <strong>Wir würden uns freuen,<br>wenn Sie Ihr MS-Erlebnis teilen<br></strong>
            </div>
            <div v-else-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" class="type-question">
              <strong>Wij zouden het zeer waarderen<br>als u uw MS-ervaring deelt.<br></strong>
            </div>
            <div v-else class="type-question">
              <strong>Nous serions ravis que vous<br> partagiez votre Expérience MS</strong>
            </div>

            <div v-if="envoi_id%2 == 0">
              <div class="lien-tripadvisor">
                <div v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12" class="type-question">
                  <!-- <a v-if="camping_id == 582" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 582" class="hide">
                    {{this.showForm582EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 4085" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g1080869-d3351218-Reviews-Camping_Club_MS_Le_Trianon-Olonne_sur_Mer_Les_Sables_d_Olonne_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 4085" class="hide">
                    {{this.showForm4085EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 1015" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g196598-d2073306-Reviews-Camping_Club_Le_Littoral-Argeles_sur_Mer_Pyrenees_Orientales_Occitanie.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 1015" class="hide">
                    {{this.showForm1015EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 4535" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g663645-d3195927-Reviews-Camping_Club_MS_La_Cote_Sauvage-La_Palmyre_Les_Mathes_Charente_Maritime_Nouvelle_Aquit.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 4535" class="hide">
                    {{this.showForm4535EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6475" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g608787-d3358092-Reviews-Camping_Club_Les_Tamaris-Le_Barcares_Perpignan_Pyrenees_Orientales_Occitanie.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6475" class="hide">
                    {{this.showForm6475EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 7610" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 7610" class="hide">
                    {{this.showForm7610EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6942" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g1572480-d3195950-Reviews-Camping_Campeole_Medoc_Plage-Vendays_Montalivet_Gironde_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6942" class="hide">
                    {{this.showForm6942EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6944" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g656490-d7196065-Reviews-Camping_Campeole_Navarrosse_Plage-Biscarrosse_Landes_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6944" class="hide">
                    {{this.showForm6944EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6940" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6940" class="hide">
                    {{this.showForm6940EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6938" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g656490-d2232824-Reviews-Camping_Campeole_Le_Vivier-Biscarrosse_Landes_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6938" class="hide">
                    {{this.showForm6938EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6937" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g1913154-d1878310-Reviews-Camping_Campeole_Les_Tourterelles-Vielle_Saint_Girons_Landes_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6937" class="hide">
                    {{this.showForm6937EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6943" target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g1080298-d3860156-Reviews-Camping_Campeole_La_Cote_Des_Roses-Narbonne_Plage_Narbonne_Aude_Occitanie.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6943" class="hide">
                    {{this.showForm6943EN = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6941" target="_blank" href="https://www.tripadvisor.fr/Hotel_Review-g196656-d3860222-Reviews-Camping_Campeole_Plage_des_Tonnelles-Saint_Jean_de_Monts_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6941" class="hide">
                    {{this.showForm6941EN = true}}
                  </div>
                </div>

                <div v-else-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" class="type-question">
                  <!-- <a v-if="camping_id == 582" target="_blank" href="https://www.tripadvisor.de/Hotel_Review-g951337-d939481-Reviews-Camping_Club_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 582" class="hide">
                    {{this.showForm582DE = true}}
                  </div>
                  <div v-if="camping_id == 4085" class="hide">
                    {{this.showForm4085DE = true}}
                  </div>
                  <div v-if="camping_id == 1015" class="hide">
                    {{this.showForm1015DE = true}}
                  </div>
                  <div v-if="camping_id == 4535" class="hide">
                    {{this.showForm4535DE = true}}
                  </div>
                  <div v-if="camping_id == 6475" class="hide">
                    {{this.showForm6475DE = true}}
                  </div>
                  <!-- <a v-if="camping_id == 7610" target="_blank" href="https://www.tripadvisor.de/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 7610" class="hide">
                    {{this.showForm7610DE = true}}
                  </div>
                  <div v-if="camping_id == 6942" class="hide">
                    {{this.showForm6942DE = true}}
                  </div>
                  <div v-if="camping_id == 6944" class="hide">
                    {{this.showForm6944DE = true}}
                  </div>
                  <div v-if="camping_id == 6940" class="hide">
                    {{this.showForm6940DE = true}}
                  </div>
                  <div v-if="camping_id == 6938" class="hide">
                    {{this.showForm6938DE = true}}
                  </div>
                  <div v-if="camping_id == 6937" class="hide">
                    {{this.showForm6937DE = true}}
                  </div>
                  <div v-if="camping_id == 6943" class="hide">
                    {{this.showForm6943DE = true}}
                  </div>
                  <div v-if="camping_id == 6941" class="hide">
                    {{this.showForm6941DE = true}}
                  </div>
                </div>

                <div v-else-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" class="type-question">
                  <!-- <a v-if="camping_id == 582" target="_blank" href="https://www.tripadvisor.nl/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 582" class="hide">
                    {{this.showForm582NL = true}}
                  </div>
                  <div v-if="camping_id == 4085" class="hide">
                    {{this.showForm4085NL = true}}
                  </div>
                  <div v-if="camping_id == 1015" class="hide">
                    {{this.showForm1015NL = true}}
                  </div>
                  <div v-if="camping_id == 4535" class="hide">
                    {{this.showForm4535NL = true}}
                  </div>
                  <div v-if="camping_id == 6475" class="hide">
                    {{this.showForm6475NL = true}}
                  </div>
                  <!-- <a v-if="camping_id == 7610" target="_blank" href="https://www.tripadvisor.nl/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 7610" class="hide">
                    {{this.showForm7610NL = true}}
                  </div>
                  <div v-if="camping_id == 6942" class="hide">
                    {{this.showForm6942NL = true}}
                  </div>
                  <div v-if="camping_id == 6944" class="hide">
                    {{this.showForm6944NL = true}}
                  </div>
                  <div v-if="camping_id == 6940" class="hide">
                    {{this.showForm6940NL = true}}
                  </div>
                  <div v-if="camping_id == 6938" class="hide">
                    {{this.showForm6938NL = true}}
                  </div>
                  <div v-if="camping_id == 6937" class="hide">
                    {{this.showForm6937NL = true}}
                  </div>
                  <div v-if="camping_id == 6943" class="hide">
                    {{this.showForm6943NL = true}}
                  </div>
                  <div v-if="camping_id == 6941" class="hide">
                    {{this.showForm6941NL = true}}
                  </div>
                </div>

                <div v-else class="type-question">
                  <!-- <a v-if="camping_id == 582" target="_blank" href="https://www.tripadvisor.fr/Hotel_Review-g951337-d939481-Reviews-Camping_Club_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 582" class="hide">
                    {{this.showForm582FR = true}}
                  </div>
                  <div v-if="camping_id == 4085" class="hide">
                    {{this.showForm4085FR = true}}
                  </div>
                  <div v-if="camping_id == 1015" class="hide">
                    {{this.showForm1015FR = true}}
                  </div>
                  <div v-if="camping_id == 4535" class="hide">
                    {{this.showForm4535FR = true}}
                  </div>
                  <div v-if="camping_id == 6475" class="hide">
                    {{this.showForm6475FR = true}}
                  </div>
                  <!-- <a v-if="camping_id == 7610" target="_blank" href="https://www.tripadvisor.fr/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 7610" class="hide">
                    {{this.showForm7610FR = true}}
                  </div>
                  <div v-if="camping_id == 6942" class="hide">
                    {{this.showForm6942FR = true}}
                  </div>
                  <div v-if="camping_id == 6944" class="hide">
                    {{this.showForm6944FR = true}}
                  </div>
                  <!-- <a v-if="camping_id == 6940" target="_blank" href="https://www.tripadvisor.fr/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html"><img src="/images/tripadvisor.png" alt="" /></a> -->
                  <div v-if="camping_id == 6940" class="hide">
                    {{this.showForm6940FR = true}}
                  </div>

                  <div v-if="camping_id == 6938" class="hide">
                    {{this.showForm6938FR = true}}
                  </div>
                  <div v-if="camping_id == 6937" class="hide">
                    {{this.showForm6937FR = true}}
                  </div>
                  <div v-if="camping_id == 6943" class="hide">
                    {{this.showForm6943FR = true}}
                  </div>
                  <div v-if="camping_id == 6941" class="hide">
                    {{this.showForm6941FR = true}}
                  </div>
                </div>
                <br>
                <br>

              </div>
            </div>
            <div v-else>
              <div class="lien-google">
                <a v-if="camping_id == 582" target="_blank" href="https://www.google.com/search?q=camping+Club+les+brunelles&rlz=1C1CAFB_enFR661FR661&oq=camping+Club+les+brunelles&aqs=chrome..69i57j46i175i199i512j0i512l2j69i60j69i61l2.4656j0j7&sourceid=chrome&ie=UTF-8#lrd=0x480413e89332d9e5:0xc3e58dfd23e272fe,1,,,"><img src="/images/google.png" alt="" /></a>
                <a v-if="camping_id == 4085" target="_blank" href="https://www.google.com/search?q=camping+club+le+trianon&rlz=1C1CAFB_enFR661FR661&oq=camping+club+le+trianon&aqs=chrome..69i57j46i175i199i512j0i512l2j69i60.3588j0j7&sourceid=chrome&ie=UTF-8#lrd=0x480442c541066719:0x632917e79fcf7abb,1,,,"><img src="/images/google.png" alt="" /></a>
                <a v-if="camping_id == 1015" target="_blank" href="https://www.google.com/search?q=camping+club+le+littoral&rlz=1C1CAFB_enFR661FR661&oq=camping+club+le+littoral&aqs=chrome..69i57j46i175i199i512j0i512l2j69i60.4772j0j7&sourceid=chrome&ie=UTF-8#lrd=0x12b07ebc976434f9:0x21267e7ffef72b4d,1,,,"><img src="/images/google.png" alt="" /></a>
                <!-- <a v-if="camping_id == 4535" target="_blank" href="https://www.google.com/search?q=camping+club+la+cote+sauvage&rlz=1C1CAFB_enFR661FR661&oq=camping+club+la+cote+sauvage&aqs=chrome..69i57j46i175i199i512j0i512j0i22i30l4j69i60.4756j0j7&sourceid=chrome&ie=UTF-8#lrd=0x48017c6ae8e39f7b:0xd59a24c61141c0e,1,,,"><img src="/images/google.png" alt="" /></a> -->
                <div v-if="camping_id == 4535" class="hide">
                  {{this.showForm4535FR = true}}
                </div>
                <a v-if="camping_id == 6475" target="_blank" href="https://www.google.com/search?q=camping+Club+les+tamaris&rlz=1C1CAFB_enFR661FR661&oq=camping+Club+les+tamaris&aqs=chrome..69i57j46i175i199i512j0i512j69i60.4380j0j7&sourceid=chrome&ie=UTF-8#lrd=0x12b061238ae7022d:0x871ce4c746c1840c,1,,,"><img src="/images/google.png" alt="" /></a>
                <!-- <a v-if="camping_id == 7610" target="_blank" href="https://www.google.com/search?q=camping+campeole+pontaillac+plage+royan&rlz=1C1CAFB_enFR661FR661&oq=camping+campeole+pontaillac+plage&aqs=chrome.0.0i355i512j46i175i199i512j69i57j69i60j69i65l2.6572j0j9&sourceid=chrome&ie=UTF-8#lrd=0x480177a90b11e79f:0xb031236d3dcaaa5d,1,,,"><img src="/images/google.png" alt="" /></a> -->
                  <div v-if="camping_id == 7610" class="hide">
                    {{this.showForm7610FR = true}}
                  </div>
                <a v-if="camping_id == 6942" target="_blank" href="https://www.google.com/search?q=camping+campeole+medoc+plage&rlz=1C1CAFB_enFR661FR661&oq=camping+campeole+medoc+plage&aqs=chrome.0.69i59j46i175i199i512j0i512j69i60j69i65l2j69i60l2.6271j0j7&sourceid=chrome&ie=UTF-8#lrd=0x48018ea9929dac5b:0x2c92166e71604fca,1,,,"><img src="/images/google.png" alt="" /></a>
                <a v-if="camping_id == 6944" target="_blank" href="https://www.google.com/search?q=camping+campeole+navarrosse+plage&rlz=1C1CAFB_enFR661FR661&oq=camping+campeole+navarrosse+plage&aqs=chrome..69i57j46i175i199i512j0i512j0i22i30l2j69i60j69i65l2.4605j0j7&sourceid=chrome&ie=UTF-8#lrd=0xd54810272143789:0xba6778736cd8b1e1,1,,,"><img src="/images/google.png" alt="" /></a>
                <!-- <a v-if="camping_id == 6940" target="_blank" href="https://www.google.com/search?q=camping+camp%C3%A9ole+plage+sud&rlz=1C1CAFB_enFR661FR661&oq=camping+camp%C3%A9ole+plage+sud&aqs=chrome..69i57j46i175i199i512j0i512j0i22i30l4j69i65.8916j0j7&sourceid=chrome&ie=UTF-8"><img src="/images/google.png" alt="" /></a> -->
                  <div v-if="camping_id == 6940" class="hide">
                    {{this.showForm6940FR = true}}
                  </div>
                <!-- <a v-if="camping_id == 6938" target="_blank" href="https://www.google.com/search?q=camping+campeole+le+vivier&rlz=1C1CAFB_enFR661FR661&oq=camping+campeole+le+vivier&aqs=chrome..69i57j46i175i199i512j0i512j0i22i30l2j69i60j69i65l2.4056j0j7&sourceid=chrome&ie=UTF-8#lrd=0xd537e65346da437:0x57751adb615af07f,1,,,"><img src="/images/google.png" alt="" /></a> -->
                  <div v-if="camping_id == 6938" class="hide">
                    {{this.showForm6938FR = true}}
                  </div>
                <a v-if="camping_id == 6937" target="_blank" href="https://www.google.com/search?q=camping+campeole+les+tourterelles&rlz=1C1CAFB_enFR661FR661&oq=%3Dcamping+campeole+les+toureterelles&aqs=chrome..69i57j0i22i30l4.6652j0j4&sourceid=chrome&ie=UTF-8#lrd=0xd53f03d501edfc5:0xb118e84f09835fc1,1,,,"><img src="/images/google.png" alt="" /></a>
                <a v-if="camping_id == 6943" target="_blank" href="https://www.google.com/search?q=camping+campeole+cote+des+roses&rlz=1C1CAFB_enFR661FR661&oq=camping+campeole+cote+des+rose&aqs=chrome.0.0i512j69i57j0i22i30j69i60j69i65l2.8985j0j7&sourceid=chrome&ie=UTF-8#lrd=0x12b101cdef838c4d:0xa30e232f37c2914b,1,,,"><img src="/images/google.png" alt="" /></a>
                <a v-if="camping_id == 6941" target="_blank" href="https://www.google.com/search?q=camping+les+tonnelles&rlz=1C1CHBF_frFR793FR793&sxsrf=ALiCzsZpv62hjYkbi0F6_JkbeVmOzfTtGA%3A1655297651476&ei=c9apYuDRHM2Lur4PteOLwAs&ved=0ahUKEwig142kwK_4AhXNhc4BHbXxArgQ4dUDCA4&uact=5&oq=camping+les+tonnelles&gs_lcp=Cgdnd3Mtd2l6EAMyCgguEMcBEK8BEEMyBQgAEIAEMgYIABAeEAcyBggAEB4QBzIGCAAQHhAKMgQIABAeMgYIABAeEAhKBAhBGABKBAhGGABQAFgAYNYBaABwAHgAgAFfiAFfkgEBMZgBAKABAcABAQ&sclient=gws-wiz#lrd=0x48051d54aea4a77d:0x597587c1631144f0,3,,,"><img src="/images/google.png" alt="" /></a>

              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="valueOuinon == 'Non' ">
          <v-col cols="12" sm="12">
            
            <div v-if="segment_id == 5 || segment_id == 6 || segment_id == 7 || segment_id == 8 || segment_id == 11 || segment_id == 12"  class="type-question">
              <div class="fontfamily-msvacancesregular">Thanks !</div>
              <strong>Your feedback allows us to <br>
              to identify points <br>
              improvement which, by all means<br>
              obvious, require <br>
              our full attention.</strong>
            </div>
            <div v-if="segment_id == 1 || segment_id == 2 || segment_id == 3 || segment_id == 4 || segment_id == 9 || segment_id == 10" class="type-question">
              <div class="fontfamily-msvacancesregular">Merci !</div>
              <strong>Votre retour nous permet<br>
              d'identifier des points<br>
              d'amélioration qui, de toute<br>
              évidence, nécessitent<br>
              toute notre attention.<br>
              <br>
              Votre équipe relation clients.</strong>
            </div>
            <div v-if="segment_id == 13 || segment_id == 14 || segment_id == 15 || segment_id == 16 || segment_id == 17 || segment_id == 18" class="type-question">
              <div class="fontfamily-msvacancesregular">Danke !</div>
              <strong>Ihre Rücksendung erlaubt uns<br>
              Punkte zu identifizieren<br>
              der Verbesserung, die, von jedem<br>
              offensichtlich, erfordern<br>
              unsere volle Aufmerksamkeit.<br>
              <br>
              Ihr Kundenbetreuungsteam.</strong>
            </div>
            <div v-if="segment_id == 19 || segment_id == 20 || segment_id == 21 || segment_id == 22 || segment_id == 23 || segment_id == 24" class="type-question">
              <div class="fontfamily-msvacancesregular">Dankjewel !</div>
              <strong>Uw retourzending stelt ons in staat<br>
              om punten te identificeren<br>
              van verbetering die, van welke<br>
              duidelijk, vereisen<br>
              onze volledige aandacht.<br>
              <br>
              Uw klantrelatieteam.</strong>
            </div>
          </v-col>
        </v-row>
      </div>
    <div>
  </div>
  </div>
  </div>

  <!-- Plage Sud - trip - FR -->
  <div id="TA_cdswritereviewlg717" :class="'TA_cdswritereviewlg ' + [this.showForm6940FR ? 'show' : 'hide']" >
    <ul id="b6Vmmj" class="TA_links lCRciv">
      <li id="gRa88wzokRt" class="TonFBAts">
        <a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html>
          <img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/>
        </a>
      </li>
    </ul>
  </div>
  <!-- Plage Sud - trip - EN -->
  <div id="TA_cdswritereviewlg735" :class="'TA_cdswritereviewlg ' + [this.showForm6940EN ? 'show' : 'hide']"><ul id="nW6oFGW" class="TA_links e0T4ha8"><li id="nEC8DnfEzW" class="numUuxP"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Plage Sud - trip - DE -->
  <div id="TA_cdswritereviewlg700" :class="'TA_cdswritereviewlg ' + [this.showForm6940DE ? 'show' : 'hide']"><ul id="qqCVh3Q3" class="TA_links ylyaK2O"><li id="ET23GY75" class="ADrE5TeS"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Plage Sud - trip - NL -->
  <div id="TA_cdswritereviewlg670" :class="'TA_cdswritereviewlg ' + [this.showForm6940NL ? 'show' : 'hide']"><ul id="SKDxraZm" class="TA_links B6fTlD1WZqY"><li id="k3IucgOe93" class="4Vu34rEBZ"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g656490-d2248166-Reviews-Camping_Campeole_Plage_Sud-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Brunelles - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg214" :class="'TA_cdswritereviewlg ' + [this.showForm582FR ? 'show' : 'hide']">
    <ul id="71Hmyd" class="TA_links oJGyih5JHTUJ">
      <li id="81rxXV" class="tvY1CPde">
        <a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html>
          <img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/>
        </a>
      </li>
    </ul>
  </div>
  <!-- Brunelles - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg29" :class="'TA_cdswritereviewlg ' + [this.showForm582EN ? 'show' : 'hide']"><ul id="81h7zOez" class="TA_links niVwnDcef"><li id="4booEb0McY" class="mJxpM85"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Brunelles - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg462" :class="'TA_cdswritereviewlg ' + [this.showForm582DE ? 'show' : 'hide']"><ul id="i0JzjZtjg" class="TA_links wbUIQrgLu8g"><li id="99yoFI8g" class="YCmUwU"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Brunelles - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg667" :class="'TA_cdswritereviewlg ' + [this.showForm582NL ? 'show' : 'hide']"><ul id="Z8fnsAGY0jB4" class="TA_links Ohk4LIWD8A5"><li id="0M21wShY9" class="HDfyCSd"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g951337-d939481-Reviews-Camping_Club_MS_Les_Brunelles-Longeville_sur_mer_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Pontaillac - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg384" :class="'TA_cdswritereviewlg ' + [this.showForm7610FR ? 'show' : 'hide']"><ul id="8P5iUJhWh4Z" class="TA_links WQlk39iEaMH"><li id="fqrdkt" class="ulK9CyX"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Pontaillac - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg138" :class="'TA_cdswritereviewlg ' + [this.showForm7610EN ? 'show' : 'hide']"><ul id="Wk5WvI" class="TA_links 7BlNRcst"><li id="FrWeiRJ" class="DNzPnAfEslu6"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Pontaillac - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg184" :class="'TA_cdswritereviewlg ' + [this.showForm7610DE ? 'show' : 'hide']"><ul id="xvonRJ" class="TA_links rBhIKwdwBphu"><li id="C0KTKzxqrzS" class="ATE6qYPCKI6"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Pontaillac - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg859" :class="'TA_cdswritereviewlg ' + [this.showForm7610NL ? 'show' : 'hide']"><ul id="n5ozi4l" class="TA_links cPHTOP5bN"><li id="ibvlr6UIf" class="aJMxbZi9"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g207350-d2713390-Reviews-Camping_Campeole_Pontaillac_Plage-Royan_Charente_Maritime_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Trianon - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg409" :class="'TA_cdswritereviewlg ' + [this.showForm4085FR ? 'show' : 'hide']"><ul id="G3w9cEC9" class="TA_links XgO6xE5yF84O"><li id="G58qp64" class="M0OMV3D8q8"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g1080869-d3351218-Reviews-Camping_Club_MS_Le_Trianon-Olonne_sur_Mer_Les_Sables_d_Olonne_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Trianon - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg729" :class="'TA_cdswritereviewlg ' + [this.showForm4085EN ? 'show' : 'hide']"><ul id="rtaMVpNKgIwf" class="TA_links WAOBGHZYY"><li id="HATeywYDi" class="eGK9vi16vG"><a target="_blank" href="https://www.tripadvisor.co.uk/Hotel_Review-g1080869-d3351218-Reviews-Camping_Club_Le_Trianon-Olonne_sur_Mer_Les_Sables_d_Olonne_Vendee_Pays_de_la_Loire.html"><img src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg" alt="TripAdvisor"/></a></li></ul></div>
  <!-- Trianon - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg608" :class="'TA_cdswritereviewlg ' + [this.showForm4085DE ? 'show' : 'hide']"><ul id="lp85MR" class="TA_links GbyZ3pFLhF"><li id="oTMoa46" class="hkqattI"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g1080869-d3351218-Reviews-Camping_Club_MS_Le_Trianon-Olonne_sur_Mer_Les_Sables_d_Olonne_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Trianon - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg301" :class="'TA_cdswritereviewlg ' + [this.showForm4085NL ? 'show' : 'hide']"><ul id="4E9bNkOz" class="TA_links gxRGFFQnzTLb"><li id="1Rv4CySauE" class="36xBHf1"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g1080869-d3351218-Reviews-Camping_Club_MS_Le_Trianon-Olonne_sur_Mer_Les_Sables_d_Olonne_Vendee_Pays_de_la_Loire.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Côte Sauvage - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg393" :class="'TA_cdswritereviewlg ' + [this.showForm4535FR ? 'show' : 'hide']"><ul id="Fek0QssF" class="TA_links obhqHXCJgA"><li id="kZRJUZATc" class="Ew6sRL"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g663645-d3195927-Reviews-Camping_Club_MS_La_Cote_Sauvage-La_Palmyre_Les_Mathes_Charente_Maritime_Nouvelle_Aquit.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte Sauvage - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg597" :class="'TA_cdswritereviewlg ' + [this.showForm4535EN ? 'show' : 'hide']"><ul id="ChU9SZmvWm" class="TA_links 8BJqUhhhyw"><li id="FbCCzqKcR" class="WewCd4dM3ol"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g663645-d3195927-Reviews-Camping_Club_MS_La_Cote_Sauvage-La_Palmyre_Les_Mathes_Charente_Maritime_Nouvelle_Aquit.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte Sauvage - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg310" :class="'TA_cdswritereviewlg ' + [this.showForm4535DE ? 'show' : 'hide']"><ul id="4QXbNnp" class="TA_links mBW38vom"><li id="aTa4VFdoSMw" class="k2mSmH24OkDT"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g663645-d3195927-Reviews-Camping_Club_MS_La_Cote_Sauvage-La_Palmyre_Les_Mathes_Charente_Maritime_Nouvelle_Aquit.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte Sauvage - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg749" :class="'TA_cdswritereviewlg ' + [this.showForm4535NL ? 'show' : 'hide']"><ul id="l2hhIxi5YW" class="TA_links bBfq6zjZC8"><li id="xB6fJlL4" class="dTAYzBy5yke"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g663645-d3195927-Reviews-Camping_Club_MS_La_Cote_Sauvage-La_Palmyre_Les_Mathes_Charente_Maritime_Nouvelle_Aquit.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Littoral - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg524" :class="'TA_cdswritereviewlg ' + [this.showForm1015FR ? 'show' : 'hide']"><ul id="JVQ2D68x8" class="TA_links A2yf9yLkZt"><li id="DWHp7xhY" class="zleda63WHc"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g196598-d2073306-Reviews-Camping_Club_MS_Le_Littoral-Argeles_sur_Mer_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Littoral - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg832" :class="'TA_cdswritereviewlg ' + [this.showForm1015EN ? 'show' : 'hide']"><ul id="CZOAY0gloj3g" class="TA_links fExfYCTLktY4"><li id="YsSzegT3tW" class="L9IIMs"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g196598-d2073306-Reviews-Camping_Club_MS_Le_Littoral-Argeles_sur_Mer_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Littoral - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg9" :class="'TA_cdswritereviewlg ' + [this.showForm1015DE ? 'show' : 'hide']"><ul id="CUCR8AcAw5" class="TA_links FOvVNMxFJkle"><li id="SM4mZBpKP" class="KlNn83Xck"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g196598-d2073306-Reviews-Camping_Club_MS_Le_Littoral-Argeles_sur_Mer_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Littoral - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg418" :class="'TA_cdswritereviewlg ' + [this.showForm1015NL ? 'show' : 'hide']"><ul id="jIoxSghqZ" class="TA_links NZDFzvdnK6"><li id="lpyTjfax" class="CF3VbayfbbeV"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g196598-d2073306-Reviews-Camping_Club_MS_Le_Littoral-Argeles_sur_Mer_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Tamaris - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg290" :class="'TA_cdswritereviewlg ' + [this.showForm6475FR ? 'show' : 'hide']"><ul id="sUkTJpgGdUEp" class="TA_links GGbLoXmB7N"><li id="dqqVQWVwWekv" class="jynXfOOQbYA"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g608787-d3358092-Reviews-Camping_Club_MS_Les_Tamaris-Le_Barcares_Perpignan_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tamaris - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg703" :class="'TA_cdswritereviewlg ' + [this.showForm6475EN ? 'show' : 'hide']"><ul id="Ad2qpW" class="TA_links 7tdIRut9"><li id="T5Hhal1" class="VV8Gmm5"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g608787-d3358092-Reviews-Camping_Club_MS_Les_Tamaris-Le_Barcares_Perpignan_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tamaris - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg939" :class="'TA_cdswritereviewlg ' + [this.showForm6475DE ? 'show' : 'hide']"><ul id="AyQ82I3hJ2Hq" class="TA_links YwfklUoW57Iw"><li id="klpFOof" class="Ua3Ph6"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g608787-d3358092-Reviews-Camping_Club_MS_Les_Tamaris-Le_Barcares_Perpignan_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tamaris - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg426" :class="'TA_cdswritereviewlg ' + [this.showForm6475NL ? 'show' : 'hide']"><ul id="dyIr5Hul" class="TA_links KGLVR8Sfx"><li id="rwn2asUJ4M" class="dVyxiHwgd8n"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g608787-d3358092-Reviews-Camping_Club_MS_Les_Tamaris-Le_Barcares_Perpignan_Pyrenees_Orientales_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Medoc Plage - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg699" :class="'TA_cdswritereviewlg ' + [this.showForm6942FR ? 'show' : 'hide']"><ul id="cARE8F34F97" class="TA_links MZQzz6X"><li id="RU0L0Sp6" class="Yo1eyBZemn5"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g1572480-d3195950-Reviews-Camping_Campeole_Medoc_Plage-Vendays_Montalivet_Gironde_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Medoc Plage - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg11" :class="'TA_cdswritereviewlg ' + [this.showForm6942EN ? 'show' : 'hide']"><ul id="gudMlU23zNAY" class="TA_links 8rU370zL"><li id="2vZbXC" class="qmA7N2qD"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g1572480-d3195950-Reviews-Camping_Campeole_Medoc_Plage-Vendays_Montalivet_Gironde_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Medoc Plage - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg236" :class="'TA_cdswritereviewlg ' + [this.showForm6942DE ? 'show' : 'hide']"><ul id="PBfEDMkr" class="TA_links 3KRgJg8I"><li id="3kWt5Bxd3" class="zJ3KGs"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g1572480-d3195950-Reviews-Camping_Campeole_Medoc_Plage-Vendays_Montalivet_Gironde_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Medoc Plage - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg778" :class="'TA_cdswritereviewlg ' + [this.showForm6942NL ? 'show' : 'hide']"><ul id="XFnZeda" class="TA_links 0miM3TVLWq"><li id="01JJqa6EZ" class="vMTHftmgEE"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g1572480-d3195950-Reviews-Camping_Campeole_Medoc_Plage-Vendays_Montalivet_Gironde_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Navarosse - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg781" :class="'TA_cdswritereviewlg ' + [this.showForm6944FR ? 'show' : 'hide']"><ul id="oZ4azXYtDJ" class="TA_links 8LtVhCkcYB"><li id="KMhrpfwz" class="99en9v"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g656490-d7196065-Reviews-Camping_Campeole_Navarrosse_Plage-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Navarosse - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg662" :class="'TA_cdswritereviewlg ' + [this.showForm6944EN ? 'show' : 'hide']"><ul id="jEgAP3a" class="TA_links udDlP6aB"><li id="k7EYmLZHQH" class="WQ3L29AsYq3"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g656490-d7196065-Reviews-Camping_Campeole_Navarrosse_Plage-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Navarosse - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg394" :class="'TA_cdswritereviewlg ' + [this.showForm6944DE ? 'show' : 'hide']"><ul id="2aRlF7Vf" class="TA_links QMyLWmKcmTsd"><li id="ChnxkRx6wh" class="J0iuLDQtopfK"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g656490-d7196065-Reviews-Camping_Campeole_Navarrosse_Plage-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Navarosse - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg423" :class="'TA_cdswritereviewlg ' + [this.showForm6944NL ? 'show' : 'hide']"><ul id="pG4hR5t24LB" class="TA_links slXfypKTt"><li id="tYWXlRErr" class="KlSHP7RC"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g656490-d7196065-Reviews-Camping_Campeole_Navarrosse_Plage-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Vivier - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg140" :class="'TA_cdswritereviewlg ' + [this.showForm6938FR ? 'show' : 'hide']"><ul id="FUndwrbXIxQT" class="TA_links nXH5uDTy"><li id="zHqM45MJHfZ" class="tVK32zPiDf8K"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g656490-d2232824-Reviews-Camping_Campeole_Le_Vivier-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Vivier - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg52" :class="'TA_cdswritereviewlg ' + [this.showForm6938EN ? 'show' : 'hide']"><ul id="OJ6jCI" class="TA_links BSgteROp"><li id="nRHfeRgBE" class="D6Z33VPllBq"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g656490-d2232824-Reviews-Camping_Campeole_Le_Vivier-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Vivier - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg489" :class="'TA_cdswritereviewlg ' + [this.showForm6938NL ? 'show' : 'hide']"><ul id="GMdSTrhVR8J" class="TA_links TBjCMR9LHT"><li id="4XNHdbf" class="ZW3QwlBO"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g656490-d2232824-Reviews-Camping_Campeole_Le_Vivier-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Vivier - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg694" :class="'TA_cdswritereviewlg ' + [this.showForm6938DE ? 'show' : 'hide']"><ul id="RYzQev4q1S" class="TA_links YqAJLwaEDrM"><li id="yqTUOwPpma" class="WG8Wgze"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g656490-d2232824-Reviews-Camping_Campeole_Le_Vivier-Biscarrosse_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Tourterelle - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg782" :class="'TA_cdswritereviewlg ' + [this.showForm6937FR ? 'show' : 'hide']"><ul id="xa6wkkCON4uV" class="TA_links g60SyC"><li id="3b9Sa05" class="0Xy5wo"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g1913154-d1878310-Reviews-Camping_Campeole_Les_Tourterelles-Vielle_Saint_Girons_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tourterelle - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg831" :class="'TA_cdswritereviewlg ' + [this.showForm6937EN ? 'show' : 'hide']"><ul id="9czQwp" class="TA_links bw6ApZouxty"><li id="m341l2vgn" class="v7FapnAcjh"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g1913154-d1878310-Reviews-Camping_Campeole_Les_Tourterelles-Vielle_Saint_Girons_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tourterelle - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg369" :class="'TA_cdswritereviewlg ' + [this.showForm6937DE ? 'show' : 'hide']"><ul id="QpQaJUq5lw" class="TA_links nxMPwmR0Ws"><li id="elG6oQGIEy" class="MqOWSBh"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g1913154-d1878310-Reviews-Camping_Campeole_Les_Tourterelles-Vielle_Saint_Girons_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Tourterelle - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg855" :class="'TA_cdswritereviewlg ' + [this.showForm6937NL ? 'show' : 'hide']"><ul id="LBbUgM98As" class="TA_links cwO4MhGcfE"><li id="p2wd4NtSLs8" class="DJe9HTl51f"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g1913154-d1878310-Reviews-Camping_Campeole_Les_Tourterelles-Vielle_Saint_Girons_Landes_Nouvelle_Aquitaine.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>

  <!-- Côte des roses - TripAdvisor - FR -->
  <div id="TA_cdswritereviewlg51" :class="'TA_cdswritereviewlg ' + [this.showForm6943FR ? 'show' : 'hide']"><ul id="wAETZc3f" class="TA_links ZM88TxjqY"><li id="47TqBQZ5VG" class="gbHnqC63"><a target="_blank" href=https://www.tripadvisor.fr/Hotel_Review-g1080298-d3860156-Reviews-Camping_Campeole_La_Cote_des_Roses-Narbonne_Plage_Narbonne_Aude_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte des roses - TripAdvisor - EN -->
  <div id="TA_cdswritereviewlg532" :class="'TA_cdswritereviewlg ' + [this.showForm6943EN ? 'show' : 'hide']"><ul id="o0IGgAbR" class="TA_links RHc7rnxMx0"><li id="LMczf9" class="LGUDBu"><a target="_blank" href=https://www.tripadvisor.co.uk/Hotel_Review-g1080298-d3860156-Reviews-Camping_Campeole_La_Cote_des_Roses-Narbonne_Plage_Narbonne_Aude_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte des roses - TripAdvisor - DE -->
  <div id="TA_cdswritereviewlg328" :class="'TA_cdswritereviewlg ' + [this.showForm6943DE ? 'show' : 'hide']"><ul id="s6Yzjj" class="TA_links vW4qXE"><li id="okpHHWo0InUp" class="SEkdhq"><a target="_blank" href=https://www.tripadvisor.de/Hotel_Review-g1080298-d3860156-Reviews-Camping_Campeole_La_Cote_des_Roses-Narbonne_Plage_Narbonne_Aude_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>
  <!-- Côte des roses - TripAdvisor - NL -->
  <div id="TA_cdswritereviewlg335" :class="'TA_cdswritereviewlg ' + [this.showForm6943NL ? 'show' : 'hide']"><ul id="IkF0mNl" class="TA_links 9EzqES"><li id="md2W6Y" class="JXaQCiRW"><a target="_blank" href=https://www.tripadvisor.nl/Hotel_Review-g1080298-d3860156-Reviews-Camping_Campeole_La_Cote_des_Roses-Narbonne_Plage_Narbonne_Aude_Occitanie.html><img src=https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg alt="TripAdvisor"/></a></li></ul></div>






 

</div>
</v-container>
</div>
</template>

<script>

import axios from '@/axios.js'

export default {
  data() {
    return {
      question_format: null,
      nbTotalQuestion : null,
      valueOuinon : null,
      texteLibre : null,
      campagne_id: null,
      form_id : null,
      client_id : null,
      envoi_id : null,
      camping_id : null,
      segment_id : null,
      date_envoi : null,
      date_reponse : null,
      smiley:[
        '/images/smiley1.png',
        '/images/smiley2.png',
        '/images/smiley3.png',
        '/images/smiley4.png'
      ],
      ouinon : {
        en:['Yes','No'],
        fr:['Oui','Non'],
        de:['Ja','Nein'],
        nl:['Ja','Nee'],
      }, 
      questions : [],
      form: {
        model: [],
        response : [],
        theme: [],
      },
      popup: '',
      question_nb : 1,
      active : false,
      slide_sens : 'gauche',
      nameRules: [
        v => (v || '' ).length <= 500 || 'Ne peut pas excéder 500 caractères'
      ],
      texte1Autre:'',
      texte2Autre:'',
      texte3Autre:'',
      texte4Autre:'',
      texte15Autre:'',
      texte16Autre:'',
      texte20Autre:'',
      texte21Autre:'',
      texte25Autre:'',
      texte26Autre:'',
      texte23Autre:'',
      texte24Autre:'',
      texte5Autre:'',
      texte6Autre:'',
      texte7Autre:'',
      texte8Autre:'',
      texte9Autre:'',
      texte10Autre:'',
      texte11Autre:'',
      texte12Autre:'',
      texte17Autre:'',
      texte18Autre:'',
      texte31Autre:'',
      texte43Autre:'',
      texteLibre26: '',
      texteLibre27: '',
      texteLibre40: '',
      texteLibre41: '',
      texteLibre43: '',
      texteLibre42: '',
      texteLibre45: '',
      langue: 'fr',
      placeholder: {
        en:'Tell us more…',
        fr:'Dites-nous en plus…',
        de:'Erzählen Sie uns mehr…',
        nl:'Vertel ons meer…',
      },
      boutonS: {
        en:'Next',
        fr:'Suivant',
        de:'Folgend',
        nl:'Volgende',
      },
      prequestion5:0,
      prequestion6:0,
      prequestion7:0,
      prequestion8:0,
      prequestion9:0,
      prequestion10:0,
      prequestion11:0,
      prequestion12:0,
      prequestion17:0,
      prequestion18:0,
      prequestion31:0,
      prequestion32:0,
      prequestion33:0,
      prequestion34:0,
      prequestion35:0,
      prequestion36:0,
      prequestion37:0,
      prequestion38:0,
      prequestion39:0,
      prequestion43:0,
      prequestion45:0,
      sauter43:0,
      sauter42:0,
      sauter45:0,
      oui43:0,
      non43:0,
      showForm582FR:false,
      showForm582EN:false,
      showForm582DE:false,
      showForm582NL:false,
      showForm7610FR:false,
      showForm7610EN:false,
      showForm7610DE:false,
      showForm7610NL:false,
      showForm4085FR:false,
      showForm4085EN:false,
      showForm4085DE:false,
      showForm4085NL:false,
      showForm1015FR:false,
      showForm1015EN:false,
      showForm1015DE:false,
      showForm1015NL:false,
      showForm4535FR:false,
      showForm4535EN:false,
      showForm4535DE:false,
      showForm4535NL:false,
      showForm6475FR:false,
      showForm6475EN:false,
      showForm6475DE:false,
      showForm6475NL:false,
      showForm6942FR:false,
      showForm6942EN:false,
      showForm6942DE:false,
      showForm6942NL:false,
      showForm6944FR:false,
      showForm6944EN:false,
      showForm6944DE:false,
      showForm6944NL:false,
      showForm6940FR:false,
      showForm6940EN:false,
      showForm6940DE:false,
      showForm6940NL:false,
      showForm6938FR:false,
      showForm6938EN:false,
      showForm6938DE:false,
      showForm6938NL:false,
      showForm6937FR:false,
      showForm6937EN:false,
      showForm6937DE:false,
      showForm6937NL:false,
      showForm6943FR:false,
      showForm6943EN:false,
      showForm6943DE:false,
      showForm6943NL:false,
      showForm6941FR:false,
      showForm6941EN:false,
      showForm6941DE:false,
      showForm6941NL:false,
    }
  },
  created(){
    this.form_id = this.$route.params.form_id
    this.client_id = this.$route.params.client_id
    this.envoi_id = this.$route.params.envoi_id
    //getDataForm
    this.getDataEnvoi()
    
    //getDataEnvoi
    

    this.getLastQuestion()
    
         
  },
  methods:{
    getDataEnvoi(){
      return new Promise (() => {
        axios.get('https://api-questionnaire.ms-vacances.com/api/envois/'+this.envoi_id, {
          header: {

          }
        }).then((response) =>{
          this.camping_id = response.data.envoi.camping_id
          this.segment_id = response.data.envoi.segment_id
          this.date_envoi = response.data.envoi.date_envoi
          this.campagne_id = response.data.envoi.campagne_id

          if(this.segment_id == 5 || this.segment_id == 6 || this.segment_id == 7 || this.segment_id == 8 || this.segment_id == 11 || this.segment_id == 12){
            this.langue = 'en'
          }
          if(this.segment_id == 1 || this.segment_id == 2 || this.segment_id == 3 || this.segment_id == 4 || this.segment_id == 9 || this.segment_id == 10){
            this.langue = 'fr'
          }
          if(this.segment_id == 13 || this.segment_id == 14 || this.segment_id == 15 || this.segment_id == 16 || this.segment_id == 17 || this.segment_id == 18){
            this.langue = 'de'
          }
          if(this.segment_id == 19 || this.segment_id == 20 || this.segment_id == 21 || this.segment_id == 22 || this.segment_id == 23 || this.segment_id == 24){
            this.langue = 'nl'
          }
          console.log(this.segment_id) 
          console.log(this.placeholder[this.langue]) 

          this.getDataForm()
        })
      })
    },
    getDataForm() {
      return new Promise (() => {
        axios.get('https://api-questionnaire.ms-vacances.com/api/formulaires2024/'+this.form_id+'/'+this.camping_id+'/'+this.envoi_id, {
          header: {

          }
        }).then((response) =>{
          this.questions = response.data.formulaire.questions
          this.nbTotalQuestion = (response.data.total_question + 1) //On ajoute page merci
          //console.log(response.data.formulaire)
        })
      })
    },
    getLastQuestion() {
      return new Promise (() => {
        axios.get('https://api-questionnaire.ms-vacances.com/api/formulaires/'+this.envoi_id+'/'+this.form_id+'/'+this.client_id+'/getlastidquestion', {
          header: {

          }
        }).then((response) =>{
          // Valentin client test infini
          if(this.client_id != '24116' && this.client_id != '7470' && response.data.lastquestion > 1){
            this.question_nb = (response.data.lastquestion) + 1
          }
        })
      })
    },
    onCardClick(n,q,item,type = null){
      let value = 0
      if(typeof n === "string"){
        value = n
        if(n.length > 500) {
          value = n.slice(0, 500)
        }else{
          value = n
        }

        // if(item.id == 45 ){
        //     console.log('dhsjgjsdfjhgfhjdgj')
        //     this.$set(this.form.model, q, n)
        //     this.$set(this.form.response, q, n)
        //     this.form.theme[item.id] = n
        //     this.saveResult(item,value)
        //     this.question_nb = (q+1) + 2
        //     this.slide_sens = 'gauche'
        //     return;
        // }
        this.$set(this.form.model, q, n)
        this.$set(this.form.response, q, n)
        this.form.theme[item.id] = n
        
      } else if (n<0) { //cas aucun avis
        this.$set(this.form.model, q, n)
        this.$set(this.form.response, q, n)
        this.form.theme[item.id] = n
      } else {
        value = parseInt((n)) + 1
        if(item.id != 43 && item.id != 44){
          if(type=="ouinon") {
            if(value == 2) {
              this.valueOuinon = 'Non'
            } else {
              this.valueOuinon = 'Oui'
            }
          }
        }
       
        
        value = parseInt((n)) + 1
        this.$set(this.form.model, (q), parseInt((n)))
        this.$set(this.form.response, (q), parseInt((n)))
        this.form.theme[item.id] = n

       

        if(item.id == 42 ){
          if(this.valueOuinon == 'Oui'){
            this.saveResult(item,value)
            this.question_nb = (q+1) + 1
            this.slide_sens = 'gauche'
            this.oui43 = 1;
            this.non43 = 0;
            return;
          }else{
            this.saveResult(item,value)
            this.question_nb = (q+1) + 1
            this.slide_sens = 'gauche'
            this.oui43 = 0;
            this.non43 = 1;
            return;
          }
        }     
      }

      if(item.id == 43 && n == -1) {
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }else{
        this.saveResult(item,value)
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }
      
    },
    saveResultChoixMultiples(item,value,valueTexte) {
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      this.date_reponse = today.toISOString()
      if(item.format == 'format-choix-multiples') {
        this.question_format = 'choix-multiples'
      }
      if(item.format == 'format-icones-texte') {
        this.question_format = 'icones-texte'
      }
      if(item.format == 'format-anonyme') {
        this.question_format = 'anonyme'
      }
      return new Promise (() => {
          axios.post('https://api-questionnaire.ms-vacances.com/api/resultats/add-result', {
            header: {
            },
            data : {
              form_id: this.form_id,
              envoi_id: this.envoi_id,
              client_id: this.client_id,
              camping_id : this.camping_id,
              segment_id : this.segment_id,
              date_envoi : this.date_envoi,
              question_id : item.id,
              reponse : value.toString(),
              reponseTexte : valueTexte,
              date_reponse : this.date_reponse,
              question_format: this.question_format,
              campagne_id: this.campagne_id,
            }
          }).then((response) =>{
            return response
          })
      })
    },
    onCardClickIcones(n,q,item){
      let value = 0

      value = parseInt((n)) + 1
      this.$set(this.form.model, (q), parseInt((n)))
      this.$set(this.form.response, (q), parseInt((n)))
      this.form.theme[item.id] = n

      if(value <= 2){
        
        //this.saveResultChoixMultiples(item,value,valueTexte)
      }else{
        this.saveResult(item,value)
        this.question_nb = (q+1) + 1
        this.slide_sens = 'gauche'
      }
    },
    onCardClickChoixMultiples(n,texte,q,item,type = null){
      
      let value = 0
      let valueTexte = ''
      if(type == "choixMultiples") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        this.form.theme[item.id] = n
        value = n
        valueTexte = texte
      }
      if(type == "iconestexte") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        this.form.theme[item.id] = n
        value = n+1
        valueTexte = texte
      }
      if(type == "choixRadio") {
        this.$set(this.form.model, (q), n)
        this.$set(this.form.response, (q), n)
        this.form.theme[item.id] = n
        value = n
        valueTexte = texte
      }


      this.saveResultChoixMultiples(item,value,valueTexte)
      this.question_nb = (q+1) + 1
      this.slide_sens = 'gauche'
    },
    saveResult(item,value) {
      const timeElapsed = Date.now()
      const today = new Date(timeElapsed)
      this.date_reponse = today.toISOString()
      // if(item.format == 'format-icones' || item.format == 'format-icones-avis' || item.format == 'format-icones-prestations') {
      //   this.question_format = 'icones'
      if(item.format == 'format-icones' || item.format == 'format-icones-texte' || item.format == 'format-icones-avis' || item.format == 'format-icones-prestations') {
        this.question_format = 'icones'
      }else if(item.format =='format-texte-libre') {
        this.question_format = 'texte-libre'
      }else {
        this.question_format = 'ouinon'
      }
      return new Promise (() => {
          axios.post('https://api-questionnaire.ms-vacances.com/api/resultats/add-result', {
            header: {

            },
            data : {
              form_id: this.form_id,
              envoi_id: this.envoi_id,
              client_id: this.client_id,
              camping_id : this.camping_id,
              segment_id : this.segment_id,
              date_envoi : this.date_envoi,
              question_id : item.id,
              reponse : value,
              reponseTexte : null,
              date_reponse : this.date_reponse,
              question_format: this.question_format,
              campagne_id: this.campagne_id,
            }
          }).then((response) =>{
            return response
          })
      })
    },
    recupQuestionLang(item) {
      var toto
      switch (this.segment_id) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 9:
        case 10:
          if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
            toto = item.question
          }else{
            toto = item.question_campeole
          }
          break
        case 5:
        case 6:
        case 7:
        case 8:
        case 11:
        case 12:
          if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
            toto = item.question_uk
          }else{
            toto = item.question_campeole_uk
          }
          break;
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
          if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
          toto = item.question_de
          }else{
            toto = item.question_campeole_de
          }
        break;
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
          toto = item.question_nl
          }else{
            toto = item.question_campeole_nl
          }
        break;
        
      }
      //console.log(this.segment_id)
      var nom = '';

      switch(this.camping_id){
        case 582: nom = 'Camping Club Les Brunelles 5*'; break;
        case 4085: nom = 'Camping Club Le Trianon 5*'; break;
        case 1015: nom = 'Camping Club Le Littoral 5*'; break;
        case 4535: nom = 'Camping Club La Côte Sauvage 5*'; break;
        case 6475: nom = 'Camping Club Les Tamaris 5*'; break;
        case 7610: nom = 'Camping Campéole Pontaillac Plage 4*'; break;
        case 6942: nom = 'Camping Campéole Medoc Plage 4*'; break ; 
        case 6944: nom = 'Camping Campéole Navarrosse Plage 4*'; break ;
        case 6940: nom = 'Camping Campéole Plage Sud 4*'; break ;
        case 6938: nom = 'Camping Campéole Le Vivier 4*'; break ;
        case 6937: nom = 'Camping Campéole Les Tourterelles 4*'; break ;
        case 6943: nom = 'Camping Campéole La Côte des Roses 4*'; break ;
      }
      
      
      console.log(this.camping_id)

      if(item.id == 43){

        if(this.oui43 == 1){
          switch(this.langue){
            case 'fr': toto = 'Nous vous remercions pour vos différents retours. Souhaitez-vous nous faire part d\'observations complémentaires ?'; break;
            case 'en': toto = 'Thank you for your feedback. Would you like to share other complementary observations with us?'; break;
            case 'de': toto = 'Wir danken Ihnen für Ihre verschiedenen Rückmeldungen. Möchten Sie uns noch etwas anderes mitteilen?'; break;
            case 'nl': toto = 'Bedankt voor uw feedback. Wilt u ons aanvullende opmerkingen sturen?'; break;
      }
          return toto
        }else{
          switch (this.segment_id) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 9:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question
              }else{
                toto = item.question_campeole
              }
              break;
            case 10:
              if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question
              }else{
                toto = item.question_campeole
              }
              break;
            case 5:
            case 6:
            case 7:
            case 8:
            case 11:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_uk
              }else{
                toto = item.question_campeole_uk
              }
              break;
            case 12:
              if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_uk
              }else{
                toto = item.question_campeole_uk
              }
              break;
            case 13:
            case 14:
            case 15:
            case 16:
            case 17:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_de
              }else{
                toto = item.question_campeole_de
              }
              break;
            case 18:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_de
              }else{
                toto = item.question_campeole_de
              }
              break;
            case 19:
            case 20:
            case 21:
            case 22:
            case 23:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_nl
              }else{
                console.log(item.question_campeole_nl)
                toto = item.question_campeole_nl
              }
              break;
            case 24:
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 1015 || this.camping_id == 4535 || this.camping_id == 6475){
                toto = item.question_nl
              }else{
                console.log(item.question_campeole_nl)
                toto = item.question_campeole_nl
              }
              break;
          }
            
          }

        } 
      return toto.replace('nom du camping', nom)
    },
    before(){
      if(this.question_nb <= 1) {
        return false
      }
      
      // if(id_item == 44){
      //   console.log('laaaaa')
      //   if(this.sauter43 == 1){
      //     console.log('sdggggggggg')
      //     this.question_nb = this.question_nb - 2
      //     this.slide_sens = 'droite'
      //     return
      //   }

      //   if(this.sauter45 == 1){
      //     console.log('sdggggggggg')
      //     this.question_nb = this.question_nb - 1
      //     this.slide_sens = 'droite'
      //     return
      //   }
       
      // }

      // if(id_item == 43){
      //   console.log('passe')
      //   if(this.sauter42 == 1){
      //     console.log('dedans')
      //     this.question_nb = this.question_nb - 2
      //     this.slide_sens = 'droite'
      //     return
      //   }       
      // }
      
      
      // if(id_item == 43){
      //     console.log('sdggggggggg')
      //     this.question_nb = this.question_nb - 2
      //     this.slide_sens = 'droite'
      //     return
      //   }
      this.question_nb = this.question_nb - 1
      this.slide_sens = 'droite'
    },
    remplirPopup(form){
      //console.log(form)
      var popup = '';
      switch(this.langue){
        case 'fr': popup = 'Pour rappel, vous semblez moyennement satisfait sur ces éléments :<ul>'; break;
        case 'en': popup = 'As a reminder, you seem moderately satisfied with these elements :<ul>'; break;
        case 'de': popup = 'Zur Erinnerung: Sie scheinen mit diesen Elementen mäßig zufrieden zu sein :<ul>'; break;
        case 'nl': popup = 'Ter herinnering: u lijkt redelijk tevreden met deze elementen :<ul>'; break;
      }
      var newForm = [];
      for (var item2 in form) {
        if(item2 == 1){newForm[1] = [item2,form[item2]];}
        if(item2 == 2){newForm[3] = [item2,form[item2]];}
        if(item2 == 3){newForm[8] = [item2,form[item2]];}
        if(item2 == 4){newForm[9] = [item2,form[item2]];}
        if(item2 == 5){newForm[10] = [item2,form[item2]];}
        if(item2 == 6){newForm[11] = [item2,form[item2]];}
        if(item2 == 7){newForm[16] = [item2,form[item2]];}
        if(item2 == 8){newForm[17] = [item2,form[item2]];}
        if(item2 == 9){newForm[18] = [item2,form[item2]];}
        if(item2 == 10){newForm[19] = [item2,form[item2]];}
        if(item2 == 11){newForm[21] = [item2,form[item2]];}
        if(item2 == 12){newForm[22] = [item2,form[item2]];}
        if(item2 == 15){newForm[4] = [item2,form[item2]];}
        if(item2 == 16){newForm[5] = [item2,form[item2]];}
        if(item2 == 17){newForm[14] = [item2,form[item2]];}
        if(item2 == 18){newForm[15] = [item2,form[item2]];}
        if(item2 == 20){newForm[6] = [item2,form[item2]];}
        if(item2 == 21){newForm[7] = [item2,form[item2]];}
        if(item2 == 23){newForm[12] = [item2,form[item2]];}
        if(item2 == 24){newForm[13] = [item2,form[item2]];}
        if(item2 == 25){newForm[2] = [item2,form[item2]];}
        if(item2 == 31){newForm[20] = [item2,form[item2]];}
      }
      console.log(newForm)
      var listThemes = '';
      for (var item in newForm) {
        console.log(newForm[item][0] +'--'+ newForm[item][1])
        var theme = '';
        switch(newForm[item][0]){
          case '1' : 
            switch(this.langue){
              case 'fr': theme = 'Disponibilité et gentillesse des équipes'; break;
              case 'en': theme = 'Availability and pleasantness of the staff'; break;
              case 'de': theme = 'Ansprechnarkeit und Freundlichkeit der Teams'; break;
              case 'nl': theme = 'Beschikbaarheid en vriendelijkheid van de teams?'; break;
            }
            
          break;
          case '2' : 
            switch(this.langue){
              case 'fr': theme = 'Propreté emplacement'; break;
              case 'en': theme = 'Pitch\'s cleanliness'; break;
              case 'de': theme = 'Sauberkeit der Stellplatz'; break;
              case 'nl': theme = 'Netheid van de staanplaats'; break;
            }
            
          break;
          case '3' : 
            switch(this.langue){
              case 'fr': theme = 'Propreté Club'; break;
              case 'en': theme = 'Camping\'s cleanliness'; break;
              case 'de': theme = 'Sauberkeit des Campingplatzes'; break;
              case 'nl': theme = 'Netheid van de camping'; break;
            }
            
          break;
          case '4' : 
            switch(this.langue){
              case 'fr': theme = 'Sécurité'; break;
              case 'en': theme = 'Safety'; break;
              case 'de': theme = 'Sicherheit'; break;
              case 'nl': theme = 'Veiligheid'; break;
            }
            
          break;
          case '5' : 
            switch(this.langue){
              case 'fr': theme = 'Animations journée'; break;
              case 'en': theme = 'Daytime Activities'; break;
              case 'de': theme = 'Tagsüber Aktivitaten'; break;
              case 'nl': theme = 'Overdag activiteiten'; break;
            }
            
          break;
          case '6' : 
            switch(this.langue){
              case 'fr': theme = 'Animations soirée'; break;
              case 'en': theme = 'Evening Activities'; break;
              case 'de': theme = 'Akitivitaten am Abend'; break;
              case 'nl': theme = '\'S avonds activieiten'; break;
            }
            
          break;
          case '7' : 
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 4535 || this.camping_id == 6475 || this.camping_id == 1015){
              switch(this.langue){
                case 'fr': theme = 'MS Bar'; break;
                case 'en': theme = 'MS Bar'; break;
                case 'de': theme = 'MS Bar'; break;
                case 'nl': theme = 'MS Bar'; break;
              }
            }else{
              switch(this.langue){
                case 'fr': theme = 'Bar'; break;
                case 'en': theme = 'Bar'; break;
                case 'de': theme = 'Bar'; break;
                case 'nl': theme = 'Bar'; break;
              }
            }
            
            
          break;
          case '8' : 
            switch(this.langue){
              case 'fr': theme = 'MS Sucré'; break;
              case 'en': theme = 'MS Sucré'; break;
              case 'de': theme = 'MS Sucré'; break;
              case 'nl': theme = 'MS Sucré'; break;
            }
            
          break;
          case '9' : 
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 4535 || this.camping_id == 6475 || this.camping_id == 1015){
              switch(this.langue){
                case 'fr': theme = 'MS Resto'; break;
                case 'en': theme = 'Restaurant'; break;
                case 'de': theme = 'Restaurant'; break;
                case 'nl': theme = 'Restaurant'; break;
              }
            }else{
              switch(this.langue){
                case 'fr': theme = 'Restaurant'; break;
                case 'en': theme = 'Restaurant'; break;
                case 'de': theme = 'Restaurant'; break;
                case 'nl': theme = 'Restaurant'; break;
              }
            }
            
            
          break;
          case '10' : 
            if(this.camping_id == 582 || this.camping_id == 4085 || this.camping_id == 4535 || this.camping_id == 6475 || this.camping_id == 1015){
              switch(this.langue){
                case 'fr': theme = 'MS Snack'; break;
                case 'en': theme = 'Snack bar'; break;
                case 'de': theme = 'Snackbar'; break;
                case 'nl': theme = 'Snackbar'; break;
              }
            }else{
              switch(this.langue){
                case 'fr': theme = 'Snack'; break;
                case 'en': theme = 'Snack bar'; break;
                case 'de': theme = 'Snackbar'; break;
                case 'nl': theme = 'Snackbar'; break;
              }
            }
          break;
          case '11' : 
            switch(this.langue){
              case 'fr': theme = 'Épicerie'; break;
              case 'en': theme = 'Grocery Store'; break;
              case 'de': theme = 'Lebensmittelgeschäft'; break;
              case 'nl': theme = 'Winkeltje'; break;
            }
            
          break;
          case '12' : 
            switch(this.langue){
              case 'fr': theme = 'Espace bien-être'; break;
              case 'en': theme = 'Wellness centre'; break;
              case 'de': theme = 'Welllnessbereich'; break;
              case 'nl': theme = 'Wellness-centrum'; break;
            }
            
          break;
          case '15' : 
            switch(this.langue){
              case 'fr': theme = 'Confort Hébergement'; break;
              case 'en': theme = 'Accomodation\'s comfort '; break;
              case 'de': theme = 'Komfort der Unterkunft'; break;
              case 'nl': theme = 'Comfort van de accommodatie'; break;
            }
            
          break;
          case '16' : 
            switch(this.langue){
              case 'fr': theme = 'Propreté Hébergement'; break;
              case 'en': theme = 'Accomodation\'s cleanliness'; break;
              case 'de': theme = 'Sauberkeit der Unterkunft'; break;
              case 'nl': theme = 'Netheid van de accommodatie'; break;
            }
            
          break;
          case '17' : 
            switch(this.langue){
              case 'fr': theme = 'Club Enfant'; break;
              case 'en': theme = 'Children\'s Club'; break;
              case 'de': theme = 'Kinderclub'; break;
              case 'nl': theme = 'Kidsclub'; break;
            }
            
          break;
          case '18' : 
            switch(this.langue){
              case 'fr': theme = 'Club Ado'; break;
              case 'en': theme = 'Teen\'s Club '; break;
              case 'de': theme = 'Teen-Club'; break;
              case 'nl': theme = 'Tienerclub'; break;
            }
            
          break;
          case '20' : 
            switch(this.langue){
              case 'fr': theme = 'Propreté Camping'; break;
              case 'en': theme = 'Camping\'s cleanliness'; break;
              case 'de': theme = 'Sauberkeit des Campingplatzes'; break;
              case 'nl': theme = 'Netheid van de camping'; break;
            }
            
          break;
          case '21' : 
            switch(this.langue){
              case 'fr': theme = 'Sécurité'; break;
              case 'en': theme = 'Safety'; break;
              case 'de': theme = 'Sicherheit'; break;
              case 'nl': theme = 'Veiligheid'; break;
            }
            
          break;
          case '23' : 
            switch(this.langue){
              case 'fr': theme = 'Club Enfant'; break;
              case 'en': theme = 'Children\'s Club'; break;
              case 'de': theme = 'Kinderclub'; break;
              case 'nl': theme = 'Kidsclub'; break;
            }
            
          break;
          case '24' : 
            switch(this.langue){
              case 'fr': theme = 'Club Ado'; break;
              case 'en': theme = 'Teen\'s Club '; break;
              case 'de': theme = 'Teen-Club'; break;
              case 'nl': theme = 'Tienerclub'; break;
            }
            
          break;
          case '25' : 
            switch(this.langue){
              case 'fr': theme = 'Confort emplacement'; break;
              case 'en': theme = ' Pitch\'s comfort'; break;
              case 'de': theme = 'Komfort der Stellplatz'; break;
              case 'nl': theme = 'Comfort van de staanplaats'; break;
            }
            
          break;
          case '31' : 
            switch(this.langue){
              case 'fr': theme = 'Point chaud'; break;
              case 'en': theme = 'Hot spot'; break;
              case 'de': theme = 'Bäcker'; break;
              case 'nl': theme = 'Bakkerij'; break;
            }
            
          break;

        }
        if((newForm[item][1] == 0 || newForm[item][1] == 1) && newForm[item][0]!= 26 && newForm[item][0]!= 27 && newForm[item][0]!= 13 && newForm[item][0]!= 19){
          listThemes+= '<li>'+theme+'</li>';
        }
      }
      if(listThemes != ''){
        popup+= listThemes;
      }else{
        return
      }
      popup+= '</ul>';
      this.popup = popup;
      //return popup;

    },
    reformFinQuestion(questionTotal,id){
      console.log(id);
      if(id == 43 || id == 44 || id == 45){
        if(this.sauter42){
          return questionTotal - 1;
        }

        if(id == 43){
          return questionTotal - 1;
        }

        if(this.sauter45){
          return questionTotal - 1;
        }
      }else{
        return questionTotal;
      }
    },
   }

  }
</script>

<style>
/* Chargement police css personnaliséé*/

@font-face {font-family:'msvacancesregular';
    src:url('/fonts/msvacancesregular-webfont.eot');
    src:url('/fonts/msvacancesregular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/fonts/msvacancesregular-webfont.woff2') format('woff2'),
    url('/fonts/msvacancesregular-webfont.woff') format('woff'),
    url('/fonts/msvacancesregular-webfont.ttf') format('truetype'),
    url('/fonts/msvacancesregular-webfont.svg#msvacancesregular') format('svg');
    font-weight:normal;
    font-style:normal;
}

.v-main__wrap > div > .container{
  padding: 0 !important;
}

.logo{
    max-width: 50%;
    margin: 0 auto;
}
.logo img{
    max-width: 100%;
}
body{
    margin: 0;
    box-sizing: border-box;
    color: #252525;

}
body .conteneur{
    max-width: 767px;
    height: 100vh;
    /*min-height:1450px;*/
    width: 100%;
    margin: 0 auto;
    background: url("/images/fond_mobile_def.jpg");
    background-size: cover;
    /* background-position: top bottom; */
    background-repeat: no-repeat no-repeat;
    text-align: center;
}
@media(min-width:768px){
  body .conteneur{
      /*max-width: 1500px;*/
      max-width: 100%;
      height: 100vh;
      /*min-height:1450px;*/
      background: url("/images/fond_bureau_def.jpg");
      background-size: cover;

  }
}
.conteneur img{
    /* margin-top: 131px; */
    margin-top: 80px;
    max-width:150px;
}

/* CSS de la partie 1 */


.fontfamily-msvacancesregular{
    font-family: 'msvacancesregular';
    font-size: 42px;
    margin: 0;
    margin-top:40px;
    margin-bottom:38px;
}
.questions .fontfamily-msvacancesregular img{
  max-height:25px;
  -webkit-transition: all 0.2s ease; /* Safari et Chrome */
  -moz-transition: all 0.2s ease; /* Firefox */
  -ms-transition: all 0.2s ease; /* Internet Explorer 9 */
  -o-transition: all 0.2s ease; /* Opera */
  transition: all 0.2s ease;
  cursor:pointer;
}
.questions .fontfamily-msvacancesregular img:hover{
   -webkit-transform:scale(1.15); /* Safari et Chrome */
  -moz-transform:scale(1.15); /* Firefox */
  -ms-transform:scale(1.15); /* Internet Explorer 9 */
  -o-transform:scale(1.15); /* Opera */
  transform:scale(1.15);
}
.oui-non:nth-child(1) div{
  width:70px;
  height:70px;
  background:#7da186;
  color:#fff;
  border-radius:50%;
}
.oui-non:nth-child(2) div{
  width:70px;
  height:70px;
  background:#bd673e;
  color:#fff;
  border-radius:50%;
}
.oui-non div div{
  font-size: 25px !important;
  line-height:47px;
  font-family: Helvetica;
  padding:10px 0;
  text-align:center;
}

.type-question{
  font-size: 21px;
  font-family: Helvetica;
}
.type-question strong{
  font-weight:500;
}

.element-1:after{
    clear:both;
    content:"";
    display:block;
}

.element-1 p{
    font-family: Helvetica;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
}
.content_answer{
  margin:0 auto;
}
.smiley,
.oui-non{
    max-width: 70px;
    width: auto;
    float: left;
    box-sizing: border-box;
    margin: 0 10px;
    margin-top: 30px;
}
.oui-non{
  float: right;
}
.element-1 img{
    margin-top: 0px;
}
.smiley div{
  font-size:1px;
}
.smiley img{
    max-width: 100%;
}
.smiley-border > div > div{
    border: 3px solid transparent;
    border-radius: 75px !important;
    cursor: pointer;
}
.smiley-border:hover > div > div img,
.smiley-border .active img{
    /* border: 3px solid black; */

    border-radius: 75px;
    /*box-shadow: 0 0 0 2px black;*/
}

.smiley-border > div > div img,
.smiley-border img,
.oui-non.smiley-border  div  div{
-webkit-transition: all 0.3s ease; /* Safari et Chrome */
-moz-transition: all 0.3s ease; /* Firefox */
-ms-transition: all 0.3s ease; /* Internet Explorer 9 */
-o-transition: all 0.3s ease; /* Opera */
transition: all 0.3s ease;
border:3px solid transparent;
border-radius:50px;
}
.smiley-border:hover > div > div img,
.smiley-border .active img,
.oui-non.smiley-border:hover  div  div{
-webkit-transform:scale(1.25); /* Safari et Chrome */
-moz-transform:scale(1.25); /* Firefox */
-ms-transform:scale(1.25); /* Internet Explorer 9 */
-o-transform:scale(1.25); /* Opera */
transform:scale(1.25);
border:3px solid #000;
}


.v-textarea{
  padding:0 15px;

}

.v-input__slot{
  border:2px solid #b8b3b0 !important;
  padding:15px;
}
.v-input__slot textarea{
  font-size:18px;
  font-family:"Helvetica";
  color:#252525;
}

.v-input__slot .v-input__append-inner{
  display:none !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border:none !important;
}
.v-text-field > .v-input__control > .v-input__slot:after{
  display:none !important;
}

.pointer {
  cursor: pointer;
  -webkit-transition: all 0.1s ease; /* Safari et Chrome */
  -moz-transition: all 0.1s ease; /* Firefox */
  -ms-transition: all 0.1s ease; /* Internet Explorer 9 */
  -o-transition: all 0.1s ease; /* Opera */
  transition: all 0.1s ease;
}
.pointer:hover{
   -webkit-transform:scale(1.05); /* Safari et Chrome */
  -moz-transform:scale(1.05); /* Firefox */
  -ms-transform:scale(1.05); /* Internet Explorer 9 */
  -o-transform:scale(1.05); /* Opera */
  transform:scale(1.05);
}

/*Override Vuetify*/

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.theme--light.v-card, .theme--light.v-sheet {
     background: none !important;
}

.separation{
  width:33%;
  max-width:332px;
  height:2px;
  background:#a0a0a0;
  margin:30px auto;
}
.lien-google img,
.lien-tripadvisor img{
  max-width:250px;
  max-height:70px;
  margin-top:15px;
}

.v-button,
.theme--light.v-btn.v-btn--has-bg{
  background-color:#7da186 !important;
  color:#fff;
  -webkit-transition: all 0.2s ease; /* Safari et Chrome */
  -moz-transition: all 0.2s ease; /* Firefox */
  -ms-transition: all 0.2s ease; /* Internet Explorer 9 */
  -o-transition: all 0.2s ease; /* Opera */
  transition: all 0.2s ease;
  cursor:pointer;
}

.que.v-button:hover,
.theme--light.v-btn.v-btn--has-bg:hover{
   -webkit-transform:scale(1.15); /* Safari et Chrome */
  -moz-transform:scale(1.15); /* Firefox */
  -ms-transform:scale(1.15); /* Internet Explorer 9 */
  -o-transform:scale(1.15); /* Opera */
  transform:scale(1.15);
}


@media(max-width:767px){
  .conteneur .logo img{
    margin-top: 70px;
  }
  /* .questions{
    height:100px;
  } */
  .fontfamily-msvacancesregular{
    font-size:30px;
  }
  .questions .fontfamily-msvacancesregular img{
    max-height:20px;
  }
  .type-question{
    font-size:18px;
    padding:0 10px;
  }
  .smiley{
    max-width:74px;
    margin:0 2px;
    margin-top:30px;
  }
  .oui-non{
    max-width:76px;
    margin:0 10px;
    margin-top:30px;
  }

  .element-1 p{
    font-size:14px;
  }

  .oui-non:nth-child(1) div,
  .oui-non:nth-child(2) div{
    width:70px;
    height:70px;
    font-weight:bold;
  }
  .oui-non div div{
    font-size:22px !important;
    padding:0 0;
    line-height:65px;
  }

  .v-input__slot textarea{
    font-size:17px;
  }
  /* body .conteneur{
     min-height:800px;
  } */

  .separation{
    width:80%;
  }
  .v-button,
  .theme--light.v-btn.v-btn--has-bg{
    margin-top:-20px
  }
}
@media(min-width:440px) and (max-width:767px){
  body .conteneur{
    background-size:cover;
  }
}


/* .v-item-group{
  height:100vh;
} */

.gauche-enter-active {
  transition: all 0.5s ease;
}
.gauche-leave-active {
  transition: all 0s ;
}
.gauche-enter
/* .gauche-leave-active below version 2.1.8 */ {
  transform: translateX(250px);
  opacity: 0;
}

.gauche-leave-to{
  display:none;
}


.droite-enter-active {
  transition: all 0.5s ease;
}
.droite-leave-active {
  transition: all 0s ;
}
.droite-enter
/* .droite-leave-active below version 2.1.8 */ {
  transform: translateX(-250px);
  opacity: 0;
}

.droite-leave-to{
  display:none;
}

.v-input.v-textarea{
  max-width:800px;
  margin:0 auto;
}

@media(max-width:767px){
  .v-item-group{
    height:auto;
  }
  .smiley-border > div > div img,
  .smiley-border img{
    border:2px solid transparent;
  }
  .smiley-border:hover > div > div img,
  .smiley-border .active img,
  .oui-non.smiley-border:hover  div  div{
    -webkit-transform:scale(1.15); /* Safari et Chrome */
    -moz-transform:scale(1.15); /* Firefox */
    -ms-transform:scale(1.15); /* Internet Explorer 9 */
    -o-transform:scale(1.15); /* Opera */
    transform:scale(1.15);
    border:2px solid #000;
  }

  .type-question-fr#question15{
    max-width:250px;
    margin:0 auto;
    padding:0 !important;
  }
  .type-question-fr#question13{
    max-width:225px;
    margin:0 auto;
    padding:0 !important;
  }
}

@media(min-width:768px){
  .container{
    max-width:100% !important;
  }
  .logo img{
    margin-top:130px;
    margin-bottom:50px;
  }
}

#listeThemes{
  display: block;
  margin: 0 auto;
}
#listeThemes,
#listeThemes *{
  font-size: 21px;
  font-family: Helvetica;
  list-style-position: inside;
}
/* .content_answer:after{
  display:block;
  content:"";
  width:1;
  clear:both;
}
.content_answer{
  display:block;
  width:100%;
} */
.content_content_answer{
  position:relative;
}
@media(min-width:768px){
  .content_content_answer .pointer{
    position:absolute;
    width:600px;
    left:-120px;
  }
}

.hide{
  display:none;
}
.widWRL{
  margin:0 auto !important;
}


</style>
